<template>
  <validation-observer ref="simpleRules">
    <b-card-code
      title="Form Tanggap Darurat"
      :class="$route.name == 'detail-tanggap-darurat' ? 'detail-dispo' : ''"
    >

      <b-button
        v-show="$route.name === 'detail-tanggap-darurat' && authorize"
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        style="position: absolute;right: 15px;top: -10px;"
        type="submit"
        @click.prevent="goEdit"
      >
        <span class="align-middle">Edit Dokumen</span>
      </b-button>
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Perihal"
            rules="required"
          >
            <b-form-group
              label="Perihal"
              label-for="Perihal"
            >
              <b-form-input
                id="Perihal"
                v-model="Perihal"
                placeholder="Input Perihal"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <v-select
                v-model="kategori"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :disabled="$route.name === 'detail-tanggap-darurat'"
                placeholder="Pilih Kategori"
                :options="optionsKategori"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal Insiden"
            rules="required"
          >
            <b-form-group
              label="Tanggal Insiden"
              label-for="Tanggal Insiden"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="date"
                locale="id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal Bantuan"
            rules="required"
          >
            <b-form-group
              label="Tanggal Bantuan"
              label-for="Tanggal Bantuan"
            >
              <b-form-datepicker
                id="date-Bantuan"
                v-model="dateHelp"
                locale="id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            name="Estimasi Kerugian"
            rules="required"
          >
            <b-form-group
              label="Estimasi Kerugian"
              label-for="Estimasi Kerugian"
            >
              <b-form-input
                id="Estimasi Kerugian"
                v-model="estimate_loss"
                placeholder="Input Estimasi Kerugian"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            name="Persentase Kerugian"
            rules="required"
          >
            <b-form-group
              label="Persentase Kerugian"
              label-for="Persentase Kerugian"
            >
              <b-form-input
                id="Persentase Kerugian"
                v-model="estimate_loss_percentage"
                placeholder="InputPersentase Kerugian"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            name="Estimasi Korban"
            rules="required"
          >
            <b-form-group
              label="Estimasi Korban"
              label-for="Estimasi Korban"
            >
              <b-form-input
                id="Estimasi Korban"
                v-model="estimate_victim"
                placeholder="Input Estimasi Korban"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Lokasi"
            rules="required"
            class="mb-1"
          >
            <b-form-group
              label="Lokasi"
              label-for="Lokasi"
            >
              <b-form-input
                id="Estimasi Lokasi"
                v-model="location"
                placeholder="Input Lokasi"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Status"
            rules="required"
            class="mb-1"
          >
            <b-form-group
              label="Status"
              label-for="Status"
            >
              <b-form-select
                v-model="status"
                placeholder="Pilih Status"
                :options="optionStatus"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <h5 class="titlerow mt-1">
            File Tanggap Darurat
          </h5>
          <b-form-group label-for="FileSurat">
            <b-row
              v-if="gantiFile == false && file !== null"
              class="match-height"
            >
              <b-col
                v-for="item in file"
                :key="item.access_url"
                md="12"
                class="fileSurat"
              >
                <div
                  class="open-file"
                  @click="openFile(item.access_url)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="56"
                  />
                  <h5 class="ml-1">
                    Open File
                    <span> {{ item.real_filename }}{{ item.ext }} </span>
                  </h5>
                </div>
              </b-col>

              <b-button
                v-show="$route.name === 'edit-disposisi'"
                v-model="gantiFile"
                variant="outline-primary"
                class="bg-gradient-primary mt-1 ml-1"
                style="height: 29px"
                size="sm"
                @click.prevent="toggleFile"
              >
                <span class="align-middle">Ganti File</span>
              </b-button>
            </b-row>

            <b-row v-else>
              <b-col md="12">
                <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;">
                  <b-form-file
                    id="FileSurat"
                    ref="file"
                    v-model="file"
                    type="file"
                    placeholder="Input File Surat"
                    drop-placeholder="Drop file here..."
                    multiple="multiple"
                    @change="fileChange"
                  />

                  <b-button
                    v-show="file !== null"
                    v-model="gantiFile"
                    size="sm"
                    variant="outline-primary"
                    class="bg-gradient-primary mt-1"
                    @click.prevent="toggleFile"
                  >
                    <span class="align-middle">Open File</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            class="mt-1"
            name="Tag"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <label
            for="textarea-default"
            class="mt-1"
          >Deskripsi</label>
          <b-form-textarea
            id="textarea-default"
            v-model="deskripsi"
            placeholder="Textarea"
            rows="5"
          />

          <label
            for="textarea-default"
            class="mt-1"
          >Catatan</label>
          <b-form-textarea
            id="textarea-default"
            v-model="catatan"
            placeholder="Textarea"
            rows="5 "
          />
        </b-col>
        <b-col md="6">
          <b-row
            v-for="(row, index) in Damages"
            :key="index"
            class="mt-1"
            style="margin-bottom: 10px;position:relative"
          >
            <h5 class="titlerow">
              Detail Kerusakan {{ index + 1 }}
            </h5>
            <span
              v-show="index !== 0"
              class="warning2"
              @click="deleteDamages(index)"
            > Hapus </span>
            <b-col md="6">
              <b-form-group
                label="Nama"
                label-for="Nama"
                class="mb-1"
              >
                <b-form-input
                  id="Nama"
                  v-model="row.name"
                  placeholder="Input Nama"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Estimasi Kerugian"
                label-for="Estimasi Kerugian"
                class="mb-1"
              >
                <b-form-input
                  id="Estimasi Kerugian"
                  v-model="row.estimate_loss"
                  placeholder="Input Estimasi Kerugian"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Catatan"
                label-for="Catatan"
              >
                <b-form-input
                  id="Catatan"
                  v-model="row.note"
                  placeholder="Input Catatan"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Deskripsi"
                label-for="Deskripsi"
              >
                <b-form-input
                  id="Deskripsi"
                  v-model="row.desc"
                  placeholder="Input Deskripsi"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="outline"
            class="bg-gradient mb-2 bt-add"
            type="submit"
            size="sm"
            @click.prevent="addDamages"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah Detail</span>
          </b-button>

          <b-row
            v-for="(row, index) in Donation"
            :key="index"
            class="mt-1"
            style="margin-bottom: 10px;position:relative"
          >
            <h5 class="titlerow">
              Donasi {{ index + 1 }}
            </h5>
            <span
              v-show="index !== 0"
              class="warning2"
              @click="deleteDonation(index)"
            > Hapus </span>
            <b-col md="6">
              <b-form-group
                label="Nama"
                label-for="Nama"
                class="mb-1"
              >
                <b-form-input
                  id="Nama"
                  v-model="row.item_name"
                  placeholder="Input Nama"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jumlah"
                label-for="Jumlah"
                class="mb-1"
              >
                <b-form-input
                  id="Jumlah"
                  v-model="row.item_value"
                  placeholder="Input Jumlah"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="outline"
            class="bg-gradient mb-2 bt-add"
            type="submit"
            size="sm"
            @click.prevent="addDonation"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah Donasi</span>
          </b-button>

          <b-row
            v-for="(row, index) in Victims"
            :key="index"
            class="mt-1"
            style="margin-bottom: 10px;position:relative"
          >
            <h5 class="titlerow">
              Korban {{ index + 1 }}
            </h5>
            <span
              v-show="index !== 0"
              class="warning2"
              @click="deleteVictims(index)"
            > Hapus </span>
            <b-col md="12">
              <b-form-group
                label="Nama"
                label-for="Nama"
                class="mb-1"
              >
                <b-form-input
                  id="Nama"
                  v-model="row.name"
                  placeholder="Input Nama"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor Identitas"
                label-for="Nomor Identitas"
                class="mb-1"
              >
                <b-form-input
                  id="Nomor Identitas"
                  v-model="row.card_id"
                  placeholder="Input Nomor Identitas"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tipe Identitas"
                label-for="Tipe Identitas"
                class="mb-1"
              >
                <b-form-input
                  id="Tipe Identitas"
                  v-model="row.card_type"
                  placeholder="Input Tipe Identitas"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jenis Kelamin"
                label-for="Jenis Kelamin"
              >
                <b-form-select
                  v-model="row.gender"
                  placeholder="Pilih Tipe Kendaraan"
                  :options="optionGender"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Status"
                label-for="Tipe Status"
                class="mb-1"
              >
                <b-form-input
                  id="Tipe Status"
                  v-model="row.status"
                  placeholder="Input Tipe Status"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <label for="textarea-default">Deskripsi</label>
              <b-form-textarea
                id="textarea-default"
                v-model="row.desc"
                placeholder="Textarea"
                rows="2"
              />
            </b-col>
            <b-col md="6">
              <label for="textarea-default">Catatan</label>
              <b-form-textarea
                id="textarea-default"
                v-model="row.note"
                placeholder="Textarea"
                rows="2"
              />
            </b-col>
          </b-row>
          <b-button
            variant="outline"
            class="bg-gradient mb-2 mt-1 bt-add"
            type="submit"
            size="sm"
            @click.prevent="addVictims"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah Korban</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button
        variant="outline-primary"
        class="bg-gradient-primary "
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>

    </b-card-code>

    <b-row class="match-height">
      <b-col md="6">
        <b-card-code
          title="Riwayat Dokumen"
          class="activity"
        >
          <app-timeline
            v-for="data in activity"
            :key="data.id"
          >
            <app-timeline-item
              :title="data.user.name"
              :subtitle="data.message"
              :time="dateFormat(data.updated_at)"
              variant="info"
              style="padding-bottom:20px"
            />
          </app-timeline>
        </b-card-code>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import store from '@/store/index'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    ToastificationContent,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      NoSurat: '',
      Perihal: '',
      estimate_loss: '',
      estimate_loss_percentage: '',
      estimate_victim: '',
      location: '',
      authorize: false,
      gantiFile: false,
      file: [],
      tags: [],
      activity: [],
      status: '',
      date: null,
      dateHelp: null,
      kategori: [],
      type: [],
      selected: [],
      deskripsi: '',
      catatan: '',
      Damages: [
        {
          name: '',
          estimate_loss: '',
          note: '',
          desc: '',
        },
      ],
      Donation: [
        {
          id: 0,
          item_name: '',
          item_value: '',
        },
      ],
      Victims: [
        {
          id: 0,
          name: '',
          card_id: '',
          card_type: '',
          gender: '',
          status: '',
          desc: '',
          note: '',
        },
      ],
      optionGender: [
        { value: '', text: 'Pilih Jenis Kelamin' },
        { value: 'M', text: 'Pria' },
        { value: 'F', text: 'Wanita' },
      ],
      optionStatus: [
        { value: '', text: 'Pilih Status' },
        { value: 'Yellow', text: 'Siaga' },
        { value: 'Orange', text: 'Bahaya' },
        { value: 'Red', text: 'Darurat' },
      ],
      optionsKategori: [
        { value: 1, text: 'Darurat Bencana Alam,' },
        { value: 2, text: 'Darurat Kebakaran' },
        { value: 3, text: 'Darurat Huru-Hara / Perang' },
        { value: 4, text: 'Lain-Lain' },
      ],
    }
  },
  created() {
    this.authorize = store.state.userData.data.is_admin === 1
    this.userRole = store.state.userData.data.role.name
  },
  mounted() {
    this.getDetail()
    this.getActivity()
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'EmergencyResponse',
        },
      })
      this.file = data.data
    },

    async victimChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'EmergencyResponse',
        },
      })
      this.attachment = data.data
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addRequest()
        }
      })
    },

    openFile(e) {
      window.open(e, '_blank')
    },
    goEdit() {
      const param = this.$route.params.pathMatch
      window.location.href = `/tanggap-darurat/edit/${param}`
    },

    toggleFile() {
      if (this.gantiFile === false) {
        this.gantiFile = true
      } else {
        this.gantiFile = false
      }
    },

    async addRequest() {
      const param = this.$route.params.pathMatch
      await axios
        .post(`siap/emergency-response/update/${param}`, {
          cat: this.kategori.text,
          title: this.Perihal,
          incident_date: dayjs(this.date).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          arrive_help_date: dayjs(this.dateHelp).format(
            'YYYY-MM-DDTHH:mm:ss+07:00',
          ),
          estimate_loss: this.estimate_loss,
          estimate_loss_percentage: this.estimate_loss_percentage,
          estimate_victim: this.estimate_victim,
          location: this.location,
          desc: this.deskripsi,
          note: this.note,
          status: this.status,
          damages: this.Damages.map(e => ({
            name: e.name,
            estimate_loss: Number(e.estimate_loss),
            note: e.note,
            desc: e.desc,
          })),
          donations: this.Donation.map(e => ({
            id: 0,
            item_name: e.item_name,
            item_value: Number(e.item_value),
          })),
          victims: this.Victims,
          file: this.file,
          tags: this.tags,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          // window.location.href = `detail-disposisi/${response.data.data.id}`
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.errors.map(e => e.message),
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async getActivity() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(
        `siap/emergency-response/activity/${param}`,
      )
      this.activity = data.data
    },

    async getDetail() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(
        `siap/emergency-response/detail/${param}`,
      )
      this.kategori = data.category.name
      this.Perihal = data.title
      this.date = data.incident_date
      this.dateHelp = data.arrive_help_date
      this.estimate_loss = data.estimate_loss
      this.estimate_loss_percentage = data.estimate_loss_percentage
      this.estimate_victim = data.estimate_victim
      this.location = data.location
      this.deskripsi = data.desc
      this.catatan = data.note
      this.status = data.status.name
      this.Damages = data.damages
      this.Donation = data.donations
      this.Victims = data.victims
      this.file = data.file
      this.tags = data.tag.map(e => e.name)
    },

    addDamages() {
      this.Damages = [
        ...this.Damages,
        {
          name: '',
          estimate_loss: '',
          note: '',
          desc: '',
        },
      ]
    },
    addDonation() {
      this.Donation = [
        ...this.Donation,
        {
          id: 0,
          item_name: '',
          item_value: '',
        },
      ]
    },
    addVictims() {
      this.Victims = [
        ...this.Victims,
        {
          id: 0,
          name: '',
          card_id: '',
          card_type: '',
          gender: '',
          status: '',
          desc: '',
          note: '',
        },
      ]
    },
    deleteDamages(index) {
      const current = this.Damages.slice()
      current.splice(index, 1)
      this.Damages = current
    },
    deleteDonation(index) {
      const current = this.Donation.slice()
      current.splice(index, 1)
      this.Donation = current
    },
    deleteVictims(index) {
      const current = this.Victims.slice()
      current.splice(index, 1)
      this.Victims = current
    },
    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY (HH:mm)')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.titlerow {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-left: 15px;
}

.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}

.bt-add {
  background: #fdf6eb;
}

.warning2 {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: -1px;
  right: 15px;
  cursor: pointer;
}

.fileSurat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #a6a6a6;
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }
}

.labelfull .custom-checkbox {
  width: 100%;
}

.detail-dispo {
  .form-control,
  .custom-select {
    border: unset;
    border-bottom: 1px solid #d8d6de;
    border-radius: 0;
    pointer-events: none;
  }
}

[dir] .vs--disabled .vs__selected {
  background-color: #00427a;
  color: #fff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #615f6f;
}

.open-file {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  transition: background-color 0.5s ease;
  &:hover {
    background: #efefef;
  }
}
.activity {
  .card-body {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
